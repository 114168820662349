import React, { useState } from 'react';
import './NavBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const NavBar = ({ handleFilterChange }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="navbar">
      <div className="navbar-top">
        <div className="slogan">
          <h1>
            <a href="/" className="navbar-title-link">
              HABSOLOGY
            </a>
          </h1>
        </div>

        <div className="menu-controls">
          <button onClick={toggleMenu} className="hamburger-menu-btn" aria-label="Toggle menu">
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
      </div>

      {menuOpen && (
        <div className="nav-overlay">
          <nav className="nav-links">
            <button className="close-menu-btn" onClick={toggleMenu} aria-label="Close menu">✖</button>
            <div className="filter-heading">Content Type</div>
            <div className="filter-option">
              <input type="checkbox" id="article-filter" name="article" onChange={() => handleFilterChange('type', 'article')} />
              <label htmlFor="article-filter">Article</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="video-filter" name="video" onChange={() => handleFilterChange('type', 'video')} />
              <label htmlFor="video-filter">Video</label>
            </div>
            
            <div className="filter-heading">Source Type</div>
            <div className="filter-option">
              <input type="checkbox" id="official-filter" name="official" onChange={() => handleFilterChange('source', 'Official')} />
              <label htmlFor="official-filter">Official</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="fan-filter" name="fan" onChange={() => handleFilterChange('source', 'Fan')} />
              <label htmlFor="fan-filter">Fan</label>
            </div>
            <div className="filter-option">
              <input type="checkbox" id="network-filter" name="network" onChange={() => handleFilterChange('source', 'Network')} />
              <label htmlFor="network-filter">Network</label>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default NavBar;
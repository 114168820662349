import React, { useState } from 'react';
import './HomePage.css';
import Banner from '../components/Banner.js';
import NavBar from './NavBar.js';
import Feed from './Feed.js';
import Footer from './Footer.js';

const HomePage = () => {
  // State to control the banner visibility
  const [showBanner] = useState(false);

  return (
    <div className="homepage">
      {/* Conditionally render Banner based on showBanner state */}
      {showBanner && (
        <Banner
          title="BREAKING NEWS!"
          subtitle="Habs Trade Jordan Harris for Patrik Laine"
          link="https://www.nhl.com/canadiens/news/laine-super-excited-for-new-opportunity-in-montreal"
        />
      )}
      <NavBar />
      <div className="feed-container">
        <Feed />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;